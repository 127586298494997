import React, { useState, useEffect } from "react"
import "./antecedentes.css"
import Container from "react-bootstrap/Container"
import { ToastContainer, toast } from "react-toastify"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Table from "react-bootstrap/Table"
import { EnviarAntecedentes } from "../../../state/actions/AntecedentesActions"
import Button from "react-bootstrap/Button"
import { useSelector, useDispatch } from "react-redux"
import DragDrop from "../../../Utils/DragDrop"

const AntecedentesComponent = ({from}) => {
  const [Folio, setFolio] = useState(0)
  const Reclamos = useSelector(state => state.ReclamosTramites.currentTramites)
  const Existentes = useSelector(state => state.Antecedentes.Existentes)
  const [Intendency, setIntendency] = useState("")

  useEffect(() => {
    
    setFolio(localStorage.getItem('folio'))
    setIntendency(localStorage.getItem('intendencia'))

  }, [])

  const [AntecedentesForm, setAntecedentesForm] = useState({})
  const EmailUsuario = useSelector(state => state.Datosmisuper.DatosMiSuper)
  const [Archivos, setArchivos] = useState([])
  const [Enviando, setEnviando] = useState(false)
  const dispatch = useDispatch()
  const datoUsuario = Reclamos.find(
    x => parseInt(x.n_reclamo) === parseInt(Folio) && x.intendencia === Intendency
  )

  const handleFiles = e => {
    if (e.target.name === "motivo_presentacion") {
      setAntecedentesForm({
        ...AntecedentesForm,
        [e.target.name]: e.target.value,
      })
    }
  }

  const handleSubmit = () => {
    if (
      AntecedentesForm.motivo_presentacion === "" ||
      AntecedentesForm.motivo_presentacion === undefined
    ) {
      toast.error("Debe ingresar un motivo.")
    } else if (Archivos.length === 0) {
      toast.error("Debe adjuntar al menos un archivo.")
    } else {
      setEnviando(true)
      const SendAntecedentes = () =>
        dispatch(
          EnviarAntecedentes(
            AntecedentesForm,
            Archivos,
            datoUsuario,
            setAntecedentesForm,
            setEnviando,
            setArchivos,
            "Antecedente",
            EmailUsuario,
            from
          )
        )
      SendAntecedentes()
    }
  }

  return (
    <>
      <ToastContainer closeOnClick={true} pauseOnHover={true} />
      <Container className="my-2">
        <Row>

          <Col xl="12" className="my-1">
            <h1 className="titulo-antecedentes-ss">
              Formulario para antecedentes adicionales
            </h1>
            <small className="text-justify d-block text-center">
              A través de este formulario podrá efectuar actualización de datos,
              adjuntar antecedentes adicionales a sus reclamo en tramitación,
              designar un representante.
            </small>
            <small className="text-justify d-block text-center text-primary text-ss">
              Reclamo N°: {Folio}
            </small>
          </Col>

          {Existentes.length !== 0 && 
          <Col xl="12" className="my-1">
            <p className="font-weight-bold">Últimos ingresos de documentación adicional asociada a este reclamo:</p>

            <Table striped bordered size="sm">
              <thead className="bg-recurso-ss">
                <tr>
                  <th>Fecha Ingreso</th>
                  <th>Folio</th>
                  <th>Detalle</th>
                </tr>
              </thead>
              <tbody>
                {Existentes.map((dato, i) => (
                  <tr key={i}>
                    <td className="align-middle">{dato.fecha_ingreso}</td>
                    <td className="align-middle">{dato.folio}</td>
                    <td className="align-middle">
                      {dato.msg}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          }

          <Col xl="12" className="my-1">
            <p className="font-weight-bold">Identificación del recurrente (Cotizante o Beneficiario):</p>

            <Table striped bordered size="sm">
              <thead className="bg-recurso-ss">
                <tr>
                  <th>Nombre</th>
                  <th>RUT</th>
                  <th>{Intendency === 'IF' ? "Aseguradora" : "Prestador"}</th>
                </tr>
              </thead>
              <tbody>
                {Reclamos.filter(
                  func => Number(func.n_reclamo) === Number(Folio) && func.intendencia === Intendency
                ).map((dato, i) => (
                  <tr key={i}>
                    <td className="align-middle">{dato.nombre_rec}</td>
                    <td className="align-middle">{dato.run_rec}</td>
                    <td className="align-middle">
                      {dato.nombre_aseguradora
                        ? dato.nombre_aseguradora
                        : dato.nombre_prestador}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>

          <Col xl="12" className="my-1">
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Motivo de la presentación:</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                name="motivo_presentacion"
                onChange={handleFiles}
                value={AntecedentesForm.motivo_presentacion}
                maxLength={2000}
              />
              <small className="d-flex justify-content-end ">
                {AntecedentesForm.motivo_presentacion
                  ? AntecedentesForm.motivo_presentacion.length
                  : "0"}
                /2000
              </small>
            </Form.Group>
          </Col>

          <Col xl="12" >
            <p>Documentación que se acompaña:</p>
          </Col>

          <Col xl="12">
            <DragDrop
              setArchivos={setArchivos}
              Archivos={Archivos}
              MaximoArchivos={4}
            />
          </Col>

          <Col xl="12" className="my-1">
            <small className="my-0 d-block">
              - Debe disponer de los antecedentes al momento de llenar el
              formulario, ya que este no guarda borrador.
            </small>
          </Col>

       

          <Col xl="12">
            <Button
              color="primary"
              type="button"
              className="d-flex mx-auto btn-notif "
              onClick={() => handleSubmit()}
              disabled={Enviando}
            >
              Enviar
              {Enviando ? (
                <div className="spinner-border spinner-border-sm ml-2" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : null}
            </Button>
          </Col>
        </Row>
      
      </Container>
    </>
  )
}

export default AntecedentesComponent
